import snappcarfix from "../assets/images/snappcarfix.png";
import viramadarpishran from "../assets/images/viramadarpishran.png";
import tahatech from "../assets/images/tahatech.png";

export const portfolioInfo = {
  full_name: 'Hesam Zandian',
  role: 'Front End Engineer',
  about_me: 'I am a Front End Engineer with +4 years of experience in building high-performance web applications. I specialize in front-end technologies and UI/UX optimization, and I am eager to learn and backend technologies. I thrive on solving technical challenges and delivering impactful solutions in fast-paced environments.',
  university: 'Shahid Rajaee Technical and Vocational College of kashan',
  certificate: 'Front End Web Development Certification FreeCodeCamp',
  graduate_degree: 'B.Sc Computer Software Engineering',
  graduate_duration: 'January 2020 – January 2022',
  resume_link: 'https://drive.google.com/file/d/1kn8AfNBlDsLzuBCsBy464uSQ_5GmwS0G/view?usp=sharing',
  work_experiences: [
    {
      company_logo: snappcarfix,
      company_name: 'SnappCarFix',
      role: 'Front End Team Lead',
      country: 'Iran',
      city: 'Isfahan',
      start_date: 'April 2022',
      end_date: 'March 2024',
      responsibilities: [
        'Led and managed a front-end team in the end-to-end execution of various projects, demonstrating hands-on involvement from conception to maintenance.',
        'Transitioned the styling system to Tailwind CSS and made React projects TypeScript-based, using Vite for enhanced performance.',
        'Played a key role in managing large-scale projects such as Carfillo and Snappcarfix, showcasing proficiency in handling complex initiatives.',
        'Designed and implemented a unified layout design system, simplifying color handling for efficient re-branding.',
        'Utilized GraphQL to optimize overall web application performance.',
        'Contributed to the recruitment process by reviewing resumes and coordinating interviews.',
        'Collaborated effectively with web designers, back-end developers, and UX designers to ensure the seamless design, development, testing, and improvement of web products.',
      ],
    },
    {
      company_logo: viramadarpishran,
      company_name: 'Vira Madar Pishran',
      role: 'Front End Developer',
      country: 'Iran',
      city: 'Isfahan',
      start_date: 'August 2020',
      end_date: 'March 2022',
      responsibilities: [
        'Planned, developed, tested, and maintained responsive websites and web applications.',
        ' Utilized chart libraries such as Nivo Charts and ApexCharts for data visualization.',
        'Successfully implemented 2 Progressive Web Applications (PWA) and 1 E-Commerce Dashboard Panel using React.',
        'Developed 3 websites using Next.js, leveraging its server-side rendering capabilities.',
        'Engineered reusable components deployed across all projects to enhance development efficiency.',
        'Restructured an e-commerce project from React to Next.js, resulting in an 80% performance improvement.',
      ],
    },
    {
      company_logo: tahatech,
      company_name: 'TahaTech',
      role: 'Front End Developer',
      country: 'Iran',
      city: 'Isfahan',
      start_date: 'Jan 2020',
      end_date: 'Aug 2020',
      responsibilities: [
        'Implemented CMS dashboards for various products, ensuring user-friendly interfaces and efficient data management.',
        ' Took on back-end responsibilities, working with Node.js, Express, and MongoDB to build and manage server-side functionalities.',
        'Collaborated with the front-end team to ensure seamless integration between front-end components and back-end services.',
        'Developed reusable components and optimized performance across multiple projects.'
      ],
    },
  ],
  skills: [
    {
      title: 'Back-end Skills',
      skills: ['Node.js', 'Express', 'MongoDB', 'Postgresql', 'MySQL'],
    },
    {
      title: 'Front-end Skills',
      skills: [
        'JavaScript',
        'TypeScript',
        'React',
        'Next',
        'HTML',
        'CSS',
        'SASS',
        'Tailwind CSS',
        'Jest',
        'Cypress',
        'StoryBook',
        'Vue (Familiar)',
      ],
    },
    {
      title: 'Soft Skills',
      skills: [
        'Agile',
        'Teamwork',
        'Time Management',
        'Documentation',
        'Team Leadership and Management',
        'Collaborative Development',
      ],
    },
    {
      title: 'Learning Now...',
      still_learning: true,
      skills: ['Back-End development', 'Database Systems & Architecture', 'PHP'],
    },
  ],
};
